export default [
  {
    tokenAddress: "0xB6eD7644C69416d67B522e20bC294A9a9B405B31",
    name: "0xBitcoin Token",
    symbol: "0xBTC",
    decimals: 8,
    exchangeAddress: "0x701564Aa6E26816147D4fa211a0779F1B774Bb9B"
  },
  {
    tokenAddress: "0xfC1E690f61EFd961294b3e1Ce3313fBD8aa4f85d",
    name: "Aave Interest bearing DAI",
    symbol: "aDAI",
    decimals: 18,
    exchangeAddress: "0x7cfab87AaC0899c093235b342AC0e5B1ACF159EB"
  },
  {
    tokenAddress: "0x737F98AC8cA59f2C68aD658E3C3d8C8963E40a4c",
    name: "Amon",
    symbol: "AMN",
    decimals: 18,
    exchangeAddress: "0xE6C198d27a5B71144B40cFa2362ae3166728e0C8"
  },
  {
    tokenAddress: "0xD46bA6D942050d489DBd938a2C909A5d5039A161",
    name: "Ampleforth",
    symbol: "AMPL",
    decimals: 9,
    exchangeAddress: "0x042dBBDc27F75d277C3D99efE327DB21Bc4fde75"
  },
  {
    tokenAddress: "0x960b236A07cf122663c4303350609A66A7B288C0",
    name: "Aragon Network Token",
    symbol: "ANT",
    decimals: 18,
    exchangeAddress: "0x077d52B047735976dfdA76feF74d4d988AC25196"
  },
  {
    tokenAddress: "0x0D8775F648430679A709E98d2b0Cb6250d2887EF",
    name: "Basic Attention Token",
    symbol: "BAT",
    decimals: 18,
    exchangeAddress: "0x2E642b8D59B45a1D8c5aEf716A84FF44ea665914",
    color: '#FF5000'
  },
  {
    tokenAddress: "0x107c4504cd79C5d2696Ea0030a8dD4e92601B82e",
    name: "Bloom Token",
    symbol: "BLT",
    decimals: 18,
    exchangeAddress: "0x0E6A53B13688018A3df8C69f99aFB19A3068D04f"
  },
  {
    tokenAddress: "0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C",
    name: "Bancor Network Token",
    symbol: "BNT",
    decimals: 18,
    exchangeAddress: "0x87d80DBD37E551F58680B4217b23aF6a752DA83F"
  },
  {
    tokenAddress: "0x26E75307Fc0C021472fEb8F727839531F112f317",
    name: "Crypto20",
    symbol: "C20",
    decimals: 18,
    exchangeAddress: "0xF7B5A4b934658025390ff69dB302BC7F2AC4a542"
  },
  {
    tokenAddress: "0x4F9254C83EB525f9FCf346490bbb3ed28a81C667",
    name: "CelerToken",
    symbol: "CELR",
    decimals: 18,
    exchangeAddress: "0x1e3740A030AF8c755c888a0ee83aC9E79e09f4F1"
  },
  {
    tokenAddress: "0xF5DCe57282A584D2746FaF1593d3121Fcac444dC",
    name: "Compound Dai",
    symbol: "cSAI",
    decimals: 8,
    exchangeAddress: "0x45A2FDfED7F7a2c791fb1bdF6075b83faD821ddE"
  },
  {
    tokenAddress: "0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643",
    name: "Compound Dai",
    symbol: "cDAI",
    decimals: 8,
    exchangeAddress: "0x34E89740adF97C3A9D3f63Cc2cE4a914382c230b"
  },
  {
    tokenAddress: "0x06AF07097C9Eeb7fD685c692751D5C66dB49c215",
    name: "Chai",
    symbol: "CHAI",
    decimals: 18,
    exchangeAddress: "0x6C3942B383bc3d0efd3F36eFa1CBE7C8E12C8A2B"
  },
  {
    tokenAddress: "0x41e5560054824eA6B0732E656E3Ad64E20e94E45",
    name: "Civic",
    symbol: "CVC",
    decimals: 8,
    exchangeAddress: "0x1C6c712b1F4a7c263B1DBd8F97fb447c945d3b9a"
  },
  {
    tokenAddress: "0x89d24A6b4CcB1B6fAA2625fE562bDD9a23260359",
    name: "Dai Stablecoin v1.0 (SAI)",
    symbol: "SAI",
    decimals: 18,
    exchangeAddress: "0x09cabEC1eAd1c0Ba254B09efb3EE13841712bE14",
    color: '#FFAE38'
  },
  {
    tokenAddress: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
    name: "Dai Stablecoin",
    symbol: "DAI",
    decimals: 18,
    exchangeAddress: "0x2a1530C4C41db0B0b2bB646CB5Eb1A67b7158667",
    color: '#FAB01E'
  },
  {
    tokenAddress: "0x0Cf0Ee63788A0849fE5297F3407f701E122cC023",
    name: "Streamr DATAcoin",
    symbol: "DATA",
    decimals: 18,
    exchangeAddress: "0x4F0d6E2179938828CfF93dA40a8BA1Df7519Ca8C"
  },
  {
    tokenAddress: "0xE0B7927c4aF23765Cb51314A0E0521A9645F0E2A",
    name: "DigixDAO",
    symbol: "DGD",
    decimals: 9,
    exchangeAddress: "0xD55C1cA9F5992A2e5E379DCe49Abf24294ABe055"
  },
  {
    tokenAddress: "0x4f3AfEC4E5a3F2A6a1A411DEF7D7dFe50eE057bF",
    name: "Digix Gold Token",
    symbol: "DGX",
    decimals: 9,
    exchangeAddress: "0xb92dE8B30584392Af27726D5ce04Ef3c4e5c9924"
  },
  {
    tokenAddress: "0xc719d010B63E5bbF2C0551872CD5316ED26AcD83",
    name: "Decentralized Insurance Protocol",
    symbol: "DIP",
    decimals: 18,
    exchangeAddress: "0x61792F290e5100FBBcBb2309F03A1Bab869fb850"
  },
  {
    tokenAddress: "0xC0F9bD5Fa5698B6505F643900FFA515Ea5dF54A9",
    name: "Donut",
    symbol: "DONUT",
    decimals: 18,
    exchangeAddress: "0xD552119eD44EC8Fa8f87c568769C67Bd02B5b3FB"
  },
  {
    tokenAddress: "0xF629cBd94d3791C9250152BD8dfBDF380E2a3B9c",
    name: "Enjin Coin",
    symbol: "ENJ",
    decimals: 18,
    exchangeAddress: "0xb99A23b1a4585fc56d0EC3B76528C27cAd427473"
  },
  {
    tokenAddress: "0x06f65b8CfCb13a9FE37d836fE9708dA38Ecb29B2",
    name: "SAINT FAME: Genesis Shirt",
    symbol: "FAME",
    decimals: 18,
    exchangeAddress: "0x5e7907aC70b9a781365c72F2acEE96710bdA042e"
  },
  {
    tokenAddress: "0x4946Fcea7C692606e8908002e55A582af44AC121",
    name: "FOAM Token",
    symbol: "FOAM",
    decimals: 18,
    exchangeAddress: "0xf79cb3BEA83BD502737586A6E8B133c378FD1fF2"
  },
  {
    tokenAddress: "0x419D0d8BdD9aF5e606Ae2232ed285Aff190E711b",
    name: "FunFair",
    symbol: "FUN",
    decimals: 8,
    exchangeAddress: "0x60a87cC7Fca7E53867facB79DA73181B1bB4238B"
  },
  {
    tokenAddress: "0x543Ff227F64Aa17eA132Bf9886cAb5DB55DCAddf",
    name: "DAOstack",
    symbol: "GEN",
    decimals: 18,
    exchangeAddress: "0x26Cc0EAb6Cb650B0Db4D0d0dA8cB5BF69F4ad692"
  },
  {
    tokenAddress: "0x6810e776880C02933D47DB1b9fc05908e5386b96",
    name: "Gnosis Token",
    symbol: "GNO",
    decimals: 18,
    exchangeAddress: "0xe8e45431b93215566BA923a7E611B7342Ea954DF"
  },
  {
    tokenAddress: "0x12B19D3e2ccc14Da04FAe33e63652ce469b3F2FD",
    name: "GRID Token",
    symbol: "GRID",
    decimals: 12,
    exchangeAddress: "0x4B17685b330307C751B47f33890c8398dF4Fe407"
  },
  {
    tokenAddress: "0x0000000000b3F879cb30FE243b4Dfee438691c04",
    name: "Gastoken.io",
    symbol: "GST2",
    decimals: 2,
    exchangeAddress: "0x929507CD3D90Ab11eC4822E9eB5A48eb3a178F19"
  },
  {
    tokenAddress: "0x493C57C4763932315A328269E1ADaD09653B9081",
    name: "Fulcrum DAI iToken",
    symbol: "iDAI",
    decimals: 18,
    exchangeAddress: "0x3E0349F5D38414008B9Bb1907ea422739BE7CD4C"
  },
  {
    tokenAddress: "0x14094949152EDDBFcd073717200DA82fEd8dC960",
    name: "Fulcrum SAI iToken",
    symbol: "iSAI",
    decimals: 18,
    exchangeAddress: "0x81eeD7F1EcbD7FA9978fcc7584296Fb0C215Dc5C"
  },
  {
    tokenAddress: "0x3212b29E33587A00FB1C83346f5dBFA69A458923",
    name: "The Tokenized Bitcoin",
    symbol: "imBTC",
    decimals: 8,
    exchangeAddress: "0xFFcf45b540e6C9F094Ae656D2e34aD11cdfdb187"
  },
  {
    tokenAddress: "0x6fB3e0A217407EFFf7Ca062D46c26E5d60a14d69",
    name: "IoTeX Network",
    symbol: "IOTX",
    decimals: 18,
    exchangeAddress: "0x084f002671a5f03D5498B1e5fb15fc0cfee9a470"
  },
  {
    tokenAddress: "0x818Fc6C2Ec5986bc6E2CBf00939d90556aB12ce5",
    name: "Kin",
    symbol: "KIN",
    decimals: 18,
    exchangeAddress: "0xb7520a5F8c832c573d6BD0Df955fC5c9b72400F7"
  },
  {
    tokenAddress: "0xdd974D5C2e2928deA5F71b9825b8b646686BD200",
    name: "Kyber Network Crystal",
    symbol: "KNC",
    decimals: 18,
    exchangeAddress: "0x49c4f9bc14884f6210F28342ceD592A633801a8b",
    color: '#31CB9E'
  },
  {
    tokenAddress: "0x514910771AF9Ca656af840dff83E8264EcF986CA",
    name: "ChainLink Token",
    symbol: "LINK",
    decimals: 18,
    exchangeAddress: "0xF173214C720f58E03e194085B1DB28B50aCDeeaD",
    color: '#3354D0'
  },
  {
    tokenAddress: "0x6c6EE5e31d828De241282B9606C8e98Ea48526E2",
    name: "HoloToken",
    symbol: "HOT",
    decimals: 18,
    exchangeAddress: "0xd4777E164c6C683E10593E08760B803D58529a8E"
  },
  {
    tokenAddress: "0xBBbbCA6A901c926F240b89EacB641d8Aec7AEafD",
    name: "LoopringCoin V2",
    symbol: "LRC",
    decimals: 18,
    exchangeAddress: "0xA539BAaa3aCA455c986bB1E25301CEF936CE1B65"
  },
  {
    tokenAddress: "0x80fB784B7eD66730e8b1DBd9820aFD29931aab03",
    name: "EthLend Token",
    symbol: "LEND",
    decimals: 18,
    exchangeAddress: "0xcaA7e4656f6A2B59f5f99c745F91AB26D1210DCe"
  },
  {
    tokenAddress: "0xA4e8C3Ec456107eA67d3075bF9e3DF3A75823DB0",
    name: "LoomToken",
    symbol: "LOOM",
    decimals: 18,
    exchangeAddress: "0x417CB32bc991fBbDCaE230C7c4771CC0D69daA6b"
  },
  {
    tokenAddress: "0x58b6A8A3302369DAEc383334672404Ee733aB239",
    name: "Livepeer Token",
    symbol: "LPT",
    decimals: 18,
    exchangeAddress: "0xc4a1C45D5546029Fd57128483aE65b56124BFA6A"
  },
  {
    tokenAddress: "0xD29F0b5b3F50b07Fe9a9511F7d86F4f4bAc3f8c4",
    name: "Liquidity.Network Token",
    symbol: "LQD",
    decimals: 18,
    exchangeAddress: "0xe3406e7D0155E0a83236eC25D34Cd3D903036669"
  },
  {
    tokenAddress: "0x0F5D2fB29fb7d3CFeE444a200298f468908cC942",
    name: "Decentraland MANA",
    symbol: "MANA",
    decimals: 18,
    exchangeAddress: "0xC6581Ce3A005e2801c1e0903281BBd318eC5B5C2"
  },
  {
    tokenAddress: "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0",
    name: "Matic Token",
    symbol: "MATIC",
    decimals: 18,
    exchangeAddress: "0x9a7A75E66B325a3BD46973B2b57c9b8d9D26a621"
  },
  {
    tokenAddress: "0x8888889213DD4dA823EbDD1e235b09590633C150",
    name: "Marblecoin",
    symbol: "MBC",
    decimals: 18,
    exchangeAddress: "0xE1b7AeC3639068b474bFbcB916580fc28A20717B"
  },
  {
    tokenAddress: "0x80f222a749a2e18Eb7f676D371F19ad7EFEEe3b7",
    name: "Magnolia Token",
    symbol: "MGN",
    decimals: 18,
    exchangeAddress: "0xdd80Ca8062c7Ef90FcA2547E6a2A126C596e611F"
  },
  {
    tokenAddress: "0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2",
    name: "Maker",
    symbol: "MKR",
    decimals: 18,
    exchangeAddress: "0x2C4Bd064b998838076fa341A83d007FC2FA50957",
    color: '#1abc9c'
  },
  {
    tokenAddress: "0xec67005c4E498Ec7f55E092bd1d35cbC47C91892",
    name: "Melon Token",
    symbol: "MLN",
    decimals: 18,
    exchangeAddress: "0xA931F4eB165AC307fD7431b5EC6eADde53E14b0C"
  },
  {
    tokenAddress: "0x957c30aB0426e0C93CD8241E2c60392d08c6aC8e",
    name: "Modum Token",
    symbol: "MOD",
    decimals: 0,
    exchangeAddress: "0xCCB98654CD486216fFF273dd025246588E77cFC1"
  },
  {
    tokenAddress: "0xB62132e35a6c13ee1EE0f84dC5d40bad8d815206",
    name: "Nexo",
    symbol: "NEXO",
    decimals: 18,
    exchangeAddress: "0x069C97DBA948175D10af4b2414969e0B88d44669"
  },
  {
    tokenAddress: "0x1776e1F26f98b1A5dF9cD347953a26dd3Cb46671",
    name: "Numeraire",
    symbol: "NMR",
    decimals: 18,
    exchangeAddress: "0x2Bf5A5bA29E60682fC56B2Fcf9cE07Bef4F6196f"
  },
  {
    tokenAddress: "0x4575f41308EC1483f3d399aa9a2826d74Da13Deb",
    name: "Orchid",
    symbol: "OXT",
    decimals: 18,
    exchangeAddress: "0xe9a5bbe41dc63D555E06746b047d624E3343EA52"
  },
  {
    tokenAddress: "0xD56daC73A4d6766464b38ec6D91eB45Ce7457c44",
    name: "Panvala pan",
    symbol: "PAN",
    decimals: 18,
    exchangeAddress: "0xF53bBFBff01c50F2D42D542b09637DcA97935fF7"
  },
  {
    tokenAddress: "0x8E870D67F660D95d5be530380D0eC0bd388289E1",
    name: "PAX",
    symbol: "PAX",
    decimals: 18,
    exchangeAddress: "0xC040d51b07Aea5d94a89Bc21E8078B77366Fc6C7"
  },
  {
    tokenAddress: "0x45804880De22913dAFE09f4980848ECE6EcbAf78",
    name: "Paxos Gold",
    symbol: "PAXG",
    decimals: 18,
    exchangeAddress: "0x0d2E1a84638bD1B6c0C260c758c39451D4587be1"
  },
  {
    tokenAddress: "0x93ED3FBe21207Ec2E8f2d3c3de6e058Cb73Bc04d",
    name: "Pinakion",
    symbol: "PNK",
    decimals: 18,
    exchangeAddress: "0xF506828B166de88cA2EDb2A98D960aBba0D2402A"
  },
  {
    tokenAddress: "0x6758B7d441a9739b98552B373703d8d3d14f9e62",
    name: "POA ERC20 on Foundation",
    symbol: "POA20",
    decimals: 18,
    exchangeAddress: "0xA2E6B3EF205FeAEe475937c4883b24E6eB717eeF"
  },
  {
    tokenAddress: "0x687BfC3E73f6af55F0CccA8450114D107E781a0e",
    name: "QChi",
    symbol: "QCH",
    decimals: 18,
    exchangeAddress: "0x755899F0540c3548b99E68C59AdB0f15d2695188"
  },
  {
    tokenAddress: "0x99ea4dB9EE77ACD40B119BD1dC4E33e1C070b80d",
    name: "Quantstamp Token",
    symbol: "QSP",
    decimals: 18,
    exchangeAddress: "0x82Db9FC4956Fa40efe1e35d881004612B5CB2cc2"
  },
  {
    tokenAddress: "0xF970b8E36e23F7fC3FD752EeA86f8Be8D83375A6",
    name: "Ripio Credit Network Token",
    symbol: "RCN",
    decimals: 18,
    exchangeAddress: "0xD91FF16Ef92568fC27F466C3c5613e43313Ab1dc"
  },
  {
    tokenAddress: "0x255Aa6DF07540Cb5d3d297f0D0D4D84cb52bc8e6",
    name: "Raiden Token",
    symbol: "RDN",
    decimals: 18,
    exchangeAddress: "0x7D03CeCb36820b4666F45E1b4cA2538724Db271C",
    color: '#999'
  },
  {
    tokenAddress: "0x408e41876cCCDC0F92210600ef50372656052a38",
    name: "Republic Token",
    symbol: "REN",
    decimals: 18,
    exchangeAddress: "0x43892992B0b102459E895B88601Bb2C76736942c"
  },
  {
    tokenAddress: "0x1985365e9f78359a9B6AD760e32412f4a445E862",
    name: "Reputation",
    symbol: "REP",
    decimals: 18,
    exchangeAddress: "0x48B04d2A05B6B604d8d5223Fd1984f191DED51af",
    color: '#553580'
  },
  {
    tokenAddress: "0x9469D013805bFfB7D3DEBe5E7839237e535ec483",
    name: "Darwinia Network Native Token",
    symbol: "RING",
    decimals: 18,
    exchangeAddress: "0xeBD8AA50b26bFa63007d61eBa777A9DdE7e43c64"
  },
  {
    tokenAddress: "0x607F4C5BB672230e8672085532f7e901544a7375",
    name: "iEx.ec Network Token",
    symbol: "RLC",
    decimals: 9,
    exchangeAddress: "0xA825CAE02B310E9901b4776806CE25db520c8642"
  },
  {
    tokenAddress: "0xB4EFd85c19999D84251304bDA99E90B92300Bd93",
    name: "Rocket Pool",
    symbol: "RPL",
    decimals: 18,
    exchangeAddress: "0x3Fb2F18065926DdB33E7571475c509541d15dA0e"
  },
  {
    tokenAddress: "0x4156D3342D5c385a87D264F90653733592000581",
    name: "Salt",
    symbol: "SALT",
    decimals: 8,
    exchangeAddress: "0xC0C59cDe851bfcbdddD3377EC10ea54A18Efb937"
  },
  {
    tokenAddress: "0x7C5A0CE9267ED19B22F8cae653F198e3E8daf098",
    name: "SANtiment network token",
    symbol: "SAN",
    decimals: 18,
    exchangeAddress: "0x8a8D7aD4b89D91983cd069C58C4AA9F2f4166298"
  },
  {
    tokenAddress: "0x5e74C9036fb86BD7eCdcb084a0673EFc32eA31cb",
    name: "Synth sETH",
    symbol: "sETH",
    decimals: 18,
    exchangeAddress: "0xe9Cf7887b93150D4F2Da7dFc6D502B216438F244",
    color: '#393939'
  },
  {
    tokenAddress: "0x3A9FfF453d50D4Ac52A6890647b823379ba36B9E",
    name: "Shuffle.Monster V3",
    symbol: "SHUF",
    decimals: 18,
    exchangeAddress: "0x536956Fab86774fb55CfaAcF496BC25E4d2B435C"
  },
  {
    tokenAddress: "0x744d70FDBE2Ba4CF95131626614a1763DF805B9E",
    name: "Status Network Token",
    symbol: "SNT",
    decimals: 18,
    exchangeAddress: "0x1aEC8F11A7E78dC22477e91Ed924Fab46e3A88Fd"
  },
  {
    tokenAddress: "0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F",
    name: "Synthetix Network Token",
    symbol: "SNX",
    decimals: 18,
    exchangeAddress: "0x3958B4eC427F8fa24eB60F42821760e88d485f7F",
    color: '#eee'
  },
  {
    tokenAddress: "0x23B608675a2B2fB1890d3ABBd85c5775c51691d5",
    name: "Unisocks Edition 0",
    symbol: "SOCKS",
    decimals: 18,
    exchangeAddress: "0x22d8432cc7aA4f8712a655fC4cdfB1baEC29FCA9"
  },
  {
    tokenAddress: "0x42d6622deCe394b54999Fbd73D108123806f6a18",
    name: "SPANK",
    symbol: "SPANK",
    decimals: 18,
    exchangeAddress: "0x4e395304655F0796bc3bc63709DB72173b9DdF98"
  },
  {
    tokenAddress: "0xB64ef51C888972c908CFacf59B47C1AfBC0Ab8aC",
    name: "StorjToken",
    symbol: "STORJ",
    decimals: 8,
    exchangeAddress: "0xA7298541E52f96d42382eCBe4f242cBcBC534d02"
  },
  {
    tokenAddress: "0x57Ab1ec28D129707052df4dF418D58a2D46d5f51",
    name: "Synth sUSD",
    symbol: "sUSD",
    decimals: 18,
    exchangeAddress: "0xB944d13b2f4047fc7bd3F7013bcf01b115fb260d",
    color: 'green'
  },
  {
    tokenAddress: "0x00006100F7090010005F1bd7aE6122c3C2CF0090",
    name: "TrueAUD",
    symbol: "TAUD",
    decimals: 18,
    exchangeAddress: "0x88dF13889E20EFa93Ff9a0C08f101F431bD9DDD7"
  },
  {
    tokenAddress: "0x00000100F2A2bd000715001920eB70D229700085",
    name: "TrueCAD",
    symbol: "TCAD",
    decimals: 18,
    exchangeAddress: "0xF996D7d9BaCb9217ca64BBce1b1cD72E0E886Be6"
  },
  {
    tokenAddress: "0x00000000441378008EA67F4284A57932B1c000a5",
    name: "TrueGBP",
    symbol: "TGBP",
    decimals: 18,
    exchangeAddress: "0x6bFa119a191576Ba26Bc5e711432aCA0cFda04DE"
  },
  {
    tokenAddress: "0x0000852600CEB001E08e00bC008be620d60031F2",
    name: "TrueHKD",
    symbol: "THKD",
    decimals: 18,
    exchangeAddress: "0x505C02B4aa1286375FBDF0c390AC0fe9209DCB05"
  },
  {
    tokenAddress: "0xaAAf91D9b90dF800Df4F55c205fd6989c977E73a",
    name: "Monolith TKN",
    symbol: "TKN",
    decimals: 8,
    exchangeAddress: "0xb6cFBf322db47D39331E306005DC7E5e6549942B"
  },
  {
    tokenAddress: "0xCb94be6f13A1182E4A4B6140cb7bf2025d28e41B",
    name: "Trustcoin",
    symbol: "TRST",
    decimals: 6,
    exchangeAddress: "0x95E4649F5209dD292cAF1F087b8F1Db3bE24927f"
  },
  {
    tokenAddress: "0x2C537E5624e4af88A7ae4060C022609376C8D0EB",
    name: "BiLira",
    symbol: "TRYB",
    decimals: 6,
    exchangeAddress: "0x122327Fd43B2C66DD9e4B6c91c8f071E217558eF"
  },
  {
    tokenAddress: "0x0000000000085d4780B73119b644AE5ecd22b376",
    name: "TrueUSD",
    symbol: "TUSD",
    decimals: 18,
    exchangeAddress: "0x5048b9d01097498Fd72F3F14bC9Bc74A5aAc8fA7",
    color: '#2B2E7F'
  },
  {
    tokenAddress: "0x09cabEC1eAd1c0Ba254B09efb3EE13841712bE14",
    name: "Uniswap V1",
    symbol: "UNI-V1:SAI",
    decimals: 18,
    exchangeAddress: "0x601c32E0580D3aef9437dB52D09f5a5D7E60eC22"
  },
  {
    tokenAddress: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    name: "USD//C",
    symbol: "USDC",
    decimals: 6,
    exchangeAddress: "0x97deC872013f6B5fB443861090ad931542878126",
    color: '#2775C9'
  },
  {
    tokenAddress: "0xA4Bdb11dc0a2bEC88d24A3aa1E6Bb17201112eBe",
    name: "StableUSD",
    symbol: "USDS",
    decimals: 6,
    exchangeAddress: "0x7Ef7191AB91dDB4D7cC347fbFA170355acbaf02D"
  },
  {
    tokenAddress: "0x8f3470A7388c05eE4e7AF3d01D8C722b0FF52374",
    name: "Veritaseum",
    symbol: "VERI",
    decimals: 18,
    exchangeAddress: "0x17e5BF07D696eaf0d14caA4B44ff8A1E17B34de3"
  },
  {
    tokenAddress: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
    name: "Wrapped BTC",
    symbol: "WBTC",
    decimals: 8,
    exchangeAddress: "0x4d2f5cFbA55AE412221182D8475bC85799A5644b",
    color: 'orange'
  },
  {
    tokenAddress: "0x09fE5f0236F0Ea5D930197DCE254d77B04128075",
    name: "Wrapped CryptoKitties",
    symbol: "WCK",
    decimals: 18,
    exchangeAddress: "0x4FF7Fa493559c40aBd6D157a0bfC35Df68d8D0aC"
  },
  {
    tokenAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    name: "Wrapped Ether",
    symbol: "WETH",
    decimals: 18,
    exchangeAddress: "0xA2881A90Bf33F03E7a3f803765Cd2ED5c8928dFb",
    color: '#627EEA'
  },
  {
    tokenAddress: "0xB4272071eCAdd69d933AdcD19cA99fe80664fc08",
    name: "CryptoFranc",
    symbol: "XCHF",
    decimals: 18,
    exchangeAddress: "0x8dE0d002DC83478f479dC31F76cB0a8aa7CcEa17"
  },
  {
    tokenAddress: "0x0f7F961648aE6Db43C75663aC7E5414Eb79b5704",
    name: "XIO Network",
    symbol: "XIO",
    decimals: 18,
    exchangeAddress: "0x7B6E5278a14d5318571d65aceD036d09c998C707"
  },
  {
    tokenAddress: "0xE41d2489571d322189246DaFA5ebDe1F4699F498",
    name: "0x Protocol Token",
    symbol: "ZRX",
    decimals: 18,
    exchangeAddress: "0xaE76c84C9262Cdb9abc0C2c8888e62Db8E22A0bF",
    color: '#999'
  }
]

export const top = [
  'DAI',
  'USDC',
  'MKR',
  'KNC',
  'SNX',
  'sETH',
  'SAI',
  'ZRX',
  'WETH',
  'LINK',
  'BAT',
  'WBTC',
  'REP',
  'LRC',
  'TUSD',
  'PAX',
]
