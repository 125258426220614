import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'mobx-react'
import { AppContext } from './lib/AppContext'
import { createBrowserHistory } from 'history'

AppContext.init()

const history = createBrowserHistory()

ReactDOM.render(
  <Provider context={new AppContext()} history={history}>
    <App history={history} />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
