export default [
    [
        { name: 'Compound', url: 'https://app.compound.finance/', text: false },
        { name: 'Bluestone', url: 'https://bluestone.live', text: false, },
    ],
    [
        { name: 'Uniswap', url: 'https://uniswap.exchange/', text: true },
        { name: 'Kyber', url: 'https://kyberswap.com/', text: true },
        { name: 'Tokenlon', url: 'https://tokenlon.im/', text: true },
    ],
    [
        { name: 'Oasis', url: 'https://oasis.app/', text: true },
        { name: 'dydx', url: 'https://dydx.exchange/', text: true },
        { name: 'Radar', url: 'https://app.radarrelay.com/', text: true },
        { name: 'Fulcrum', url: 'https://fulcrum.trade/#/lend', text: false },
        { name: 'DDEX', url: 'https://ddex.io/', text: true },
        { name: 'Aave', url: 'https://app.aave.com/', text: false, small: true },
        { name: 'Nuo', url: 'https://app.nuo.network/', text: false },
    ],
    [
        { name: 'Synthetix', url: 'https://www.synthetix.io/', text: false, small: true },
        { name: 'TokenSets', url: 'https://www.tokensets.com/', text: false },
        { name: 'Tornado', url: 'https://tornado.cash/', text: false },
    ]
]
