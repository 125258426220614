import React, { Component } from 'react'
import { PieChart, Pie, Sector } from 'recharts'

interface ArcProps {
  cx: number
  cy: number
  midAngle: number
  innerRadius: number
  outerRadius: number
  startAngle: number
  endAngle: number
  fill?: string
  payload: PieData
  percent: number
  value: number
}

const renderActiveShape = (props: ArcProps) => {
  const RADIAN = Math.PI / 180
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'
  const accentColor = payload.color ?? '#8884d8'

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={accentColor}>
        {payload.name}
      </text>
      <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={accentColor} />
      <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 6} outerRadius={outerRadius + 10} fill={accentColor} />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={accentColor} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={accentColor} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill={accentColor}>{`${value.toFixed(2)} DAI`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill={accentColor}>
        {`${payload.name} (${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  )
}

const data = [
  { name: 'DAI', value: 20 },
  { name: 'USDC', value: 20 },
  { name: 'WETH', value: 20 },
  { name: 'SNX', value: 20 },
  { name: 'BAT', value: 20 }
]

interface PieData {
  name?: string
  value: number
  color?: string
}

interface Props {
  className?: string
  width: number
  height: number
  cx: number
  cy: number
  pieColor?: string
  stroke?: string
  data?: PieData[]
  innerRadius?: number
  outerRadius?: number
  onDataActived?: (data: PieData) => void
}

interface State {
  activeIndex: number
}

class ActiveShapePieChart extends Component<Props, State> {
  state: State = { activeIndex: 0 }

  onPieEnter = (data: PieData, index: number) => {
    this.setState({
      activeIndex: index
    })

    this.props.onDataActived?.(data)
  }

  render() {
    return (
      <div className={this.props.className}>
        <PieChart width={this.props.width} height={this.props.height}>
          <Pie
            dataKey="value"
            stroke={this.props.stroke}
            activeIndex={this.state.activeIndex}
            activeShape={renderActiveShape}
            data={this.props.data ?? data}
            cx={this.props.cx}
            cy={this.props.cy}
            innerRadius={this.props.innerRadius ?? 75}
            outerRadius={this.props.outerRadius ?? 90}
            fill={this.props.pieColor ?? '#8884d8'}
            onMouseEnter={this.onPieEnter}
          />
        </PieChart>
      </div>
    )
  }
}

export default ActiveShapePieChart
