import React, { Component } from 'react'
import './App.scss'
import { Uniswap, Dashboard, Landing } from './pages'
import { NavBar } from './components'
import { Router, Route, Switch } from 'react-router-dom'
import { inject } from 'mobx-react'
import { AppContext } from './lib/AppContext'
import { History } from 'history'

interface Props {
  context?: AppContext
  history: History
}

@inject('context')
class App extends Component<Props, {}> {
  render() {
    return (
      <Router history={this.props.history}>
        <div className="app">
          <div className="nav-col">
            <NavBar />
          </div>

          <Switch>
            <Route path="/uniswap" exact component={Uniswap} />
            <Route path="/dashboard" exact component={Dashboard} />
            <Route path="/" component={Landing} />
          </Switch>
        </div>
      </Router>
    )
  }
}

export default App
