import React, { Component } from 'react'
import './NavBar.scss'
import { History } from 'history'
import unicorn from '../assets/icons/unicorn.svg'
import logo from '../assets/icons/diamond.svg'
import dashboard from '../assets/icons/dashboard.svg'
import { Link } from 'react-router-dom'
import { inject } from 'mobx-react'

interface Props {
  history?: History
}
interface State {
  tab?: string
}

const tabs = [
  { img: dashboard, tab: 'dashboard' },
  { img: unicorn, tab: 'uniswap' }
]

@inject('history')
class NavBar extends Component<Props, State> {
  state: State = {}

  componentDidMount() {
    const { history } = this.props

    const updatePath = () => {
      const tab = history?.location.pathname.replace(/\//g, '')
      this.setState({ tab })
    }

    history?.listen(() => updatePath())
    updatePath()
  }

  render() {
    return (
      <div className="navbar">
        <div className="logo">
          <img src={logo} alt="DeFi123" />
        </div>

        <div className="menu">
          {tabs.map(t => {
            return (
              <Link to={`/${t.tab}`} key={t.tab} onClick={_ => this.setState({ tab: t.tab })}>
                <div className={`icon ${this.state.tab === t.tab ? 'active' : ''}`}>
                  <img src={t.img} alt={t.tab} />
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    )
  }
}

export default NavBar
