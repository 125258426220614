import React, { Component } from 'react'
import './Landing.scss'
import metamask from '../assets/brands/metamask.svg'
import { observer, inject } from 'mobx-react'
import { AppContext } from '../lib/AppContext'
import { History } from 'history'

interface Props {
  context?: AppContext
  history: History
}

@inject('context')
@inject('history')
@observer
class Landing extends Component<Props, {}> {
  async connectWallet() {
    if (await this.props.context!.enableWeb3()) {
      this.props.history.push('/dashboard')
    }
  }

  render() {
    return (
      <div className="landing page">
        <div className="container">
          <div className="title">Login with Web3 Wallet</div>
          <div className="wallets">
            <div className="wallet metamask" onClick={_ => this.connectWallet()}>
              <img src={metamask} alt="Metamask" />
              <span>Metamask</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Landing
