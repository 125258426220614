import React, { Component } from 'react'
import './Dashboard.scss'
import CountUp from 'react-countup'
import ActiveShapePieChart from '../components/ActiveShapePieChart'
import { AppContext } from '../lib/AppContext'
import { observer, inject } from 'mobx-react'
import Loading from 'react-loading'
import dapps from '../lib/DeFiApps'

interface Props {
  context?: AppContext
}

@inject('context')
@observer
class Dashboard extends Component<Props, {}> {
  async componentDidMount() {
    await this.props.context?.enableWeb3()
    await this.props.context?.refreshAssets()
  }

  render() {
    const { context } = this.props

    return (
      <div className="dashboard page">
        <div className="title">Dashboard</div>

        <div className="content">
          <div className="portfolio">
            <div className="balance">
              <div className="label">Total Value</div>
              <div className="value">
                <CountUp start={Math.max(context!.assetsValue - 1, 0)} end={context!.assetsValue} decimals={2} />
                <span className="unit">DAI</span>
              </div>
            </div>

            <div className="assets">
              <table>
                <thead>
                  <tr>
                    <th>Asset</th>
                    <th>Price</th>
                    <th>Balance</th>
                    <th>Value</th>
                  </tr>
                </thead>

                <tbody>
                  {context?.assets.map(a => {
                    return (
                      <tr key={a.asset}>
                        <td className="market">
                          <div>
                            <img src={require(`../assets/crypto/${a.asset.toLowerCase()}.svg`)} alt="" /> {a.asset}
                          </div>
                        </td>
                        <td>{`${a.price.toFixed(2)} DAI`}</td>
                        <td>{`${a.balance.toFixed(2)} ${a.asset}`}</td>
                        <td>{`${a.value.toFixed(2)} DAI`}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>

              {context!.assets.length === 0 ? (
                <div className="loading-container">
                  <Loading width={24} height={24} type="spinningBubbles" className="loader" />
                </div>
              ) : (
                undefined
              )}
            </div>
          </div>

          <div className="slide">
            <div className="label">My Assets</div>

            {context?.assets.length === 0 ? (
              <div className="charts" style={{ height: 300 }}>
                <Loading width={24} height={24} type="spinningBubbles" className="loader" />
              </div>
            ) : (
              <ActiveShapePieChart
                className="charts"
                data={context?.assets.map(a => {
                  return { value: a.value, color: a.color, name: a.asset }
                })}
                width={520}
                height={300}
                cx={270}
                cy={125}
                innerRadius={73}
                outerRadius={85}
                stroke="#333"
              />
            )}

            <div className="shortcuts">
              <div className="label">DeFi Shortcuts</div>

              <div className="dapps">
                {dapps.map((d, i) => {
                  return (
                    <div className="category" key={i}>
                      {d.map(b => {
                        return (
                          <a href={b.url} target="_blank" key={b.name}>
                            <div className="brand">
                              <img className={`${b.small ? 'small' : ''}`} src={require(`../assets/brands/${b.name.toLowerCase()}.svg`)} alt={b.name} />
                              {b.text ? <span>{b.name}</span> : undefined}
                            </div>
                          </a>
                        )
                      })}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Dashboard
