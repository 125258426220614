import Web3 from 'web3'
import Pool from './UniswapV1Pool'
import tokens, { top } from './Tokens';
import { observable } from 'mobx';
import UniswapV1Pool from './UniswapV1Pool';
import { utils } from 'ethers';
import { ERC20_ABI } from '../abi';
import { Metamask } from 'ethpay.core'
import { EventEmitter } from 'events';

export class AppContext extends EventEmitter {
  private static web3: Web3

  static init() {
    const provider = Metamask.getProvider()
    if (!provider) return

    AppContext.web3 = new Web3(provider)
  }

  @observable account!: string
  @observable pools: UniswapV1Pool[] = []
  @observable ethPrice!: number
  @observable totalFees!: number
  @observable total24HFees!: number
  @observable userPools: UniswapV1Pool[] = []
  @observable eth: number = 0
  @observable assets: { asset: string, balance: number, value: number, price: number, color?: string }[] = []
  @observable assetsValue: number = 0

  private uniswapTimer!: NodeJS.Timeout

  constructor() {
    super()

    Metamask.on('accountsChanged', (accounts: string[]) => {
      this.account = accounts[0]
      this.emit('accountsChanged', accounts)
    })
  }

  async enableWeb3() {
    if (this.account) {
      return this.account
    }

    const [account] = await Metamask.requestAccounts()
    this.account = account

    return account;
  }

  async initUniswap(account: string) {
    if (!account) return

    this.pools = []
    this.userPools = []
    clearTimeout(this.uniswapTimer)

    for (let ex of top.map(t => tokens.find(i => i.symbol === t))) {
      this.pools.push(new Pool({ ...ex!, accountAddress: account }, AppContext.web3))
    }

    await Promise.all(this.pools.map(p => p.init()))
    await this.refreshUniswap()
  }

  async refreshUniswap() {
    await Promise.all(this.pools.map(p => p.refresh()))
    await this.updatePoolsPrice()
    this.uniswapTimer = setTimeout(() => this.refreshUniswap(), 3 * 60 * 1000)
  }

  async updatePoolsPrice() {
    const daiPool = this.pools.find(p => p.symbol === 'DAI')!
    this.ethPrice = Number.parseFloat(utils.formatEther(daiPool.exchangeRate))

    for (let ex of this.pools) {
      ex.calcDaiRate(this.ethPrice)

      if (this.userPools.includes(ex)) continue

      if (ex.token24HProfit.gt(0) || ex.totalProfit.gt(0)) {
        this.userPools.push(ex)
      }
    }

    this.total24HFees = this.pools.reduce((p, c) => p + Number.parseFloat(utils.formatUnits(c.token24HProfit, c.decimals)) * c.price, 0)
    this.totalFees = this.pools.reduce((p, c) => p + Number.parseFloat(utils.formatUnits(c.totalProfit, c.decimals)) * c.price, 0)
  }

  async refreshAssets() {
    const balance = await AppContext.web3.eth.getBalance(this.account)
    this.eth = Number.parseFloat(utils.formatEther(balance))

    const pools = top.map(t => tokens.find(i => i.symbol === t)).map(ex => new Pool({ ...ex!, accountAddress: this.account }, AppContext.web3))
    await Promise.all(pools.map(p => p.refreshPoolInfo()))

    const ethPrice = Number.parseFloat(utils.formatEther(pools.find(p => p.symbol === 'DAI')!.exchangeRate))
    pools.forEach(p => p.calcDaiRate(ethPrice))

    // Add USDT
    pools.push({ tokenAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7', symbol: 'USDT', decimals: 6, price: 1, color: 'green' } as any)

    this.assets = await Promise.all(pools.map(async p => {
      const c = new AppContext.web3.eth.Contract(ERC20_ABI, p.tokenAddress)
      const tokenBalance = await c.methods.balanceOf(this.account).call()
      const amount = Number.parseFloat(utils.formatUnits(tokenBalance, p.decimals))
      const value = amount * p.price

      return { asset: p.symbol, balance: amount, value, price: p.price, color: p.color }
    }))

    this.assets.unshift({ asset: 'ETH', balance: this.eth, price: ethPrice, value: this.eth * ethPrice, color: '#627EEA' })

    this.assetsValue = this.assets.reduce((p, c) => p + c.value, 0)
  }
}
